import { useEffect, useState } from 'react';
import { logoBVAsset } from './assets';
import BvStyleguide from '@bv/styleguide';
import * as singleSpa from 'single-spa';

export default function App(props) {
  const [redirect, setRedirect] = useState('/login');

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      setRedirect('/dashboard');
    }
  }, []);

  return (
    <section className="z-1 fixed left-0 top-0 flex h-screen w-screen flex-col items-center justify-center bg-white">
      <img src={logoBVAsset} alt="Logo" className="absolute left-4 top-4 w-32" />
      <h1 className="text-primary-dark text-5xl font-bold">404</h1>
      <p className="text-primary-dark/80">A não encontramos a página que está tentando acessar.</p>
      <BvStyleguide.Button onClick={() => singleSpa.navigateToUrl(redirect)}>
        <span>Ir para início</span>
      </BvStyleguide.Button>
    </section>
  );
}
